var render = function () {
  var _vm$subTaskStatistics, _vm$subTaskStatistics2, _vm$taskStatistics, _vm$taskStatistics$da;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "lg": "6"
    }
  }, [_c('b-card-actions', {
    attrs: {
      "title": "Filters",
      "action-collapse": ""
    }
  }, [_c('div', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "searchTerm"
    }
  }, [_c('b-form-input', {
    staticClass: "custom-font",
    attrs: {
      "placeholder": "Search By Task Name ...",
      "type": "text"
    },
    on: {
      "keyup": _vm.onSearch,
      "input": _vm.taskFiltering
    },
    model: {
      value: _vm.searchTerm,
      callback: function callback($$v) {
        _vm.searchTerm = $$v;
      },
      expression: "searchTerm"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('v-select', {
    staticClass: "custom-font",
    attrs: {
      "options": _vm.filterProjectIdOption,
      "reduce": function reduce(task) {
        return task.id;
      },
      "label": "name",
      "placeholder": "Select Project"
    },
    on: {
      "input": _vm.loadItems
    },
    model: {
      value: _vm.filterProjectId,
      callback: function callback($$v) {
        _vm.filterProjectId = $$v;
      },
      expression: "filterProjectId"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('b-form-group', [_c('v-select', {
    staticClass: "custom-font",
    attrs: {
      "options": _vm.filterMilestoneBoardIdOption,
      "reduce": function reduce(board) {
        return board.id;
      },
      "label": "name",
      "placeholder": "Select Milestone Board"
    },
    on: {
      "input": _vm.setMilestoneBoardWiseInfo
    },
    model: {
      value: _vm.filterMilestoneBoardId,
      callback: function callback($$v) {
        _vm.filterMilestoneBoardId = $$v;
      },
      expression: "filterMilestoneBoardId"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('b-form-group', [_c('v-select', {
    staticClass: "custom-font",
    attrs: {
      "options": _vm.filterDepertmentIdOption,
      "reduce": function reduce(depertment) {
        return depertment.id;
      },
      "label": "name",
      "placeholder": "Select Department"
    },
    on: {
      "input": _vm.setDepartmentWiseInfo
    },
    model: {
      value: _vm.filterDepertmentId,
      callback: function callback($$v) {
        _vm.filterDepertmentId = $$v;
      },
      expression: "filterDepertmentId"
    }
  })], 1)], 1), _vm.filterDepertmentId ? _c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('b-form-group', [_c('v-select', {
    staticClass: "custom-font",
    attrs: {
      "options": _vm.userIdOption,
      "reduce": function reduce(member) {
        return member.id;
      },
      "label": "name",
      "placeholder": "Select Department Assignee"
    },
    on: {
      "input": _vm.loadSelectedMember
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('UserSelect', {
          attrs: {
            "user": data
          }
        })];
      }
    }], null, false, 3839851139),
    model: {
      value: _vm.filterMemberId,
      callback: function callback($$v) {
        _vm.filterMemberId = $$v;
      },
      expression: "filterMemberId"
    }
  })], 1)], 1) : _c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('b-form-group', [_c('v-select', {
    staticClass: "custom-font",
    attrs: {
      "options": _vm.filterAllUserIdOption,
      "reduce": function reduce(member) {
        return member.id;
      },
      "label": "name",
      "placeholder": "Select Assignee"
    },
    on: {
      "input": _vm.loadSelectedMember
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('UserSelect', {
          attrs: {
            "user": data
          }
        })];
      }
    }]),
    model: {
      value: _vm.filterMemberId,
      callback: function callback($$v) {
        _vm.filterMemberId = $$v;
      },
      expression: "filterMemberId"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('v-select', {
    staticClass: "custom-font",
    attrs: {
      "options": _vm.filterAllUserIdOption,
      "reduce": function reduce(member) {
        return member.id;
      },
      "label": "name",
      "placeholder": "Select Supervisor"
    },
    on: {
      "input": _vm.loadSelectedMember
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('UserSelect', {
          attrs: {
            "user": data
          }
        })];
      }
    }]),
    model: {
      value: _vm.filterSupervisorId,
      callback: function callback($$v) {
        _vm.filterSupervisorId = $$v;
      },
      expression: "filterSupervisorId"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('b-form-group', [_c('v-select', {
    staticClass: "custom-font",
    attrs: {
      "options": _vm.filterTaskStatusOption,
      "reduce": function reduce(item) {
        return item.value;
      },
      "label": "name",
      "placeholder": "Select Status"
    },
    on: {
      "input": _vm.loadSelectedTaskStatus
    },
    model: {
      value: _vm.filterTaskStatus,
      callback: function callback($$v) {
        _vm.filterTaskStatus = $$v;
      },
      expression: "filterTaskStatus"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('flat-pickr', {
    staticClass: "form-control",
    attrs: {
      "placeholder": "Select Deadline",
      "config": {
        mode: 'range'
      }
    },
    on: {
      "input": _vm.loadItems
    },
    model: {
      value: _vm.filterDeadlineRangeDate,
      callback: function callback($$v) {
        _vm.filterDeadlineRangeDate = $$v;
      },
      expression: "filterDeadlineRangeDate"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('flat-pickr', {
    staticClass: "form-control",
    attrs: {
      "placeholder": "Select Actual End Date",
      "config": {
        mode: 'range'
      }
    },
    on: {
      "input": _vm.loadItems
    },
    model: {
      value: _vm.filterActualEndRangeDate,
      callback: function callback($$v) {
        _vm.filterActualEndRangeDate = $$v;
      },
      expression: "filterActualEndRangeDate"
    }
  })], 1)], 1)], 1)])], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "lg": "3"
    }
  }, [_c('TaskStatistics', {
    attrs: {
      "title": 'Task Statistics',
      "statistics": _vm.taskStatistics
    }
  })], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "lg": "3"
    }
  }, [_c('TaskStatistics', {
    attrs: {
      "title": 'Sub Task Statistics',
      "statistics": _vm.subTaskStatistics
    }
  })], 1)], 1), _c('b-card-actions', {
    attrs: {
      "title": 'Subtask (' + ((_vm$subTaskStatistics = _vm.subTaskStatistics) === null || _vm$subTaskStatistics === void 0 ? void 0 : (_vm$subTaskStatistics2 = _vm$subTaskStatistics.data) === null || _vm$subTaskStatistics2 === void 0 ? void 0 : _vm$subTaskStatistics2.total_task_count) + ')',
      "action-collapse": "",
      "collapsed": ""
    }
  }, [_c('vue-good-table', {
    attrs: {
      "mode": "remote",
      "style-class": "vgt-table table-custom-style striped",
      "total-rows": _vm.subTaskTotalRecords,
      "is-loading": _vm.isLoading,
      "rows": _vm.subTaskrows,
      "sort-options": {
        enabled: false,
        multipleColumns: true,
        initialSortBy: [{
          field: 'created_at',
          type: 'desc'
        }]
      },
      "columns": _vm.columns,
      "pagination-options": {
        enabled: true,
        perPage: _vm.pageLength
      }
    },
    on: {
      "update:isLoading": function updateIsLoading($event) {
        _vm.isLoading = $event;
      },
      "update:is-loading": function updateIsLoading($event) {
        _vm.isLoading = $event;
      },
      "on-page-change": _vm.onPageChange,
      "on-per-page-change": _vm.onPerPageChange
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        var _props$column, _props$row, _props$row2, _props$row2$task, _props$row2$task$data, _props$row3, _props$row4, _props$row5, _props$row5$milestone, _props$row5$milestone2, _props$row5$milestone3, _props$row5$milestone4, _props$row6, _props$row6$milestone, _props$row6$milestone2, _props$row6$milestone3, _props$row6$milestone4, _props$row7, _props$row7$milestone, _props$row7$milestone2, _props$row7$milestone3, _props$row7$milestone4, _props$row8, _props$row8$phase, _props$row8$phase$dat, _props$row9, _props$row9$phase, _props$row9$phase$dat, _props$row$milestoneT, _props$row$milestoneT2, _props$row10, _props$row10$mileston, _props$row10$mileston2, _props$row11, _props$row11$mileston, _props$row11$mileston2, _props$row12, _props$row12$mileston, _props$row12$mileston2, _props$row13, _props$row13$mileston, _props$row13$mileston2, _props$row14, _props$row15, _props$column2, _props$row16, _props$row17, _props$row18, _props$column3, _props$row20, _props$row20$phase, _props$row20$phase$da, _props$column4, _props$row21, _props$row21$mileston, _props$row21$mileston2, _props$row21$mileston3, _props$row22, _props$row22$mileston, _props$row22$mileston2, _props$row22$mileston3, _props$column5, _props$row23, _props$row24, _props$row25, _props$row26, _props$row27, _props$row28;
        return [(props === null || props === void 0 ? void 0 : (_props$column = props.column) === null || _props$column === void 0 ? void 0 : _props$column.field) === 'format_tasks' ? [_c('span', [_c('b-link', {
          on: {
            "click": function click($event) {
              return _vm.onShowSubTaskDetailsPage(props.row.id);
            }
          }
        }, [_c('div', {
          staticClass: "font-weight-bold",
          staticStyle: {
            "color": "black"
          },
          domProps: {
            "innerHTML": _vm._s(_vm.breakTitle(props === null || props === void 0 ? void 0 : (_props$row = props.row) === null || _props$row === void 0 ? void 0 : _props$row.title))
          }
        })]), _c('div', [_vm._v(" Task: "), _c('b-link', {
          on: {
            "click": function click($event) {
              var _props$row$task, _props$row$task$data, _props$row$task2, _props$row$task2$data;
              return _vm.onShowTaskDetailsPage((_props$row$task = props.row.task) === null || _props$row$task === void 0 ? void 0 : (_props$row$task$data = _props$row$task.data) === null || _props$row$task$data === void 0 ? void 0 : _props$row$task$data.id, (_props$row$task2 = props.row.task) === null || _props$row$task2 === void 0 ? void 0 : (_props$row$task2$data = _props$row$task2.data) === null || _props$row$task2$data === void 0 ? void 0 : _props$row$task2$data.short_title);
            }
          }
        }, [_c('div', {
          staticClass: "font-weight-bold",
          staticStyle: {
            "color": "black"
          },
          domProps: {
            "innerHTML": _vm._s(_vm.breakTitle(props === null || props === void 0 ? void 0 : (_props$row2 = props.row) === null || _props$row2 === void 0 ? void 0 : (_props$row2$task = _props$row2.task) === null || _props$row2$task === void 0 ? void 0 : (_props$row2$task$data = _props$row2$task.data) === null || _props$row2$task$data === void 0 ? void 0 : _props$row2$task$data.title))
          }
        })])], 1), props !== null && props !== void 0 && (_props$row3 = props.row) !== null && _props$row3 !== void 0 && _props$row3.is_completed ? _c('b-badge', {
          staticClass: "badge-glow",
          attrs: {
            "variant": "light-success"
          }
        }, [_vm._v(" Completed ")]) : _vm._e(), props !== null && props !== void 0 && (_props$row4 = props.row) !== null && _props$row4 !== void 0 && _props$row4.overdue ? _c('b-badge', {
          attrs: {
            "variant": "light-danger"
          }
        }, [_vm._v(" Overdue ")]) : _vm._e(), _c('div', [_vm._v(" Department: "), props !== null && props !== void 0 && (_props$row5 = props.row) !== null && _props$row5 !== void 0 && (_props$row5$milestone = _props$row5.milestoneTaskBoard) !== null && _props$row5$milestone !== void 0 && (_props$row5$milestone2 = _props$row5$milestone.data) !== null && _props$row5$milestone2 !== void 0 && (_props$row5$milestone3 = _props$row5$milestone2.department) !== null && _props$row5$milestone3 !== void 0 && (_props$row5$milestone4 = _props$row5$milestone3.data) !== null && _props$row5$milestone4 !== void 0 && _props$row5$milestone4.name ? [props !== null && props !== void 0 && (_props$row6 = props.row) !== null && _props$row6 !== void 0 && (_props$row6$milestone = _props$row6.milestoneTaskBoard) !== null && _props$row6$milestone !== void 0 && (_props$row6$milestone2 = _props$row6$milestone.data) !== null && _props$row6$milestone2 !== void 0 && (_props$row6$milestone3 = _props$row6$milestone2.department) !== null && _props$row6$milestone3 !== void 0 && (_props$row6$milestone4 = _props$row6$milestone3.data) !== null && _props$row6$milestone4 !== void 0 && _props$row6$milestone4.name ? _c('b-badge', {
          attrs: {
            "variant": "light-info",
            "pill": ""
          }
        }, [_vm._v(" " + _vm._s(props === null || props === void 0 ? void 0 : (_props$row7 = props.row) === null || _props$row7 === void 0 ? void 0 : (_props$row7$milestone = _props$row7.milestoneTaskBoard) === null || _props$row7$milestone === void 0 ? void 0 : (_props$row7$milestone2 = _props$row7$milestone.data) === null || _props$row7$milestone2 === void 0 ? void 0 : (_props$row7$milestone3 = _props$row7$milestone2.department) === null || _props$row7$milestone3 === void 0 ? void 0 : (_props$row7$milestone4 = _props$row7$milestone3.data) === null || _props$row7$milestone4 === void 0 ? void 0 : _props$row7$milestone4.name) + " ")]) : _vm._e()] : _c('b-badge', {
          attrs: {
            "variant": "light-danger",
            "pill": ""
          }
        }, [_vm._v(" N / A ")])], 2), _vm._v(" Phase: "), props !== null && props !== void 0 && (_props$row8 = props.row) !== null && _props$row8 !== void 0 && (_props$row8$phase = _props$row8.phase) !== null && _props$row8$phase !== void 0 && (_props$row8$phase$dat = _props$row8$phase.data) !== null && _props$row8$phase$dat !== void 0 && _props$row8$phase$dat.title ? [_c('b-badge', {
          attrs: {
            "variant": "light-primary"
          }
        }, [_vm._v(" " + _vm._s(props === null || props === void 0 ? void 0 : (_props$row9 = props.row) === null || _props$row9 === void 0 ? void 0 : (_props$row9$phase = _props$row9.phase) === null || _props$row9$phase === void 0 ? void 0 : (_props$row9$phase$dat = _props$row9$phase.data) === null || _props$row9$phase$dat === void 0 ? void 0 : _props$row9$phase$dat.title) + " ")])] : _c('b-badge', {
          attrs: {
            "variant": "light-danger",
            "pill": ""
          }
        }, [_vm._v(" N / A ")]), _c('div', [_vm._v(" Milestone Title: "), (_props$row$milestoneT = props.row.milestoneTaskBoard) !== null && _props$row$milestoneT !== void 0 && (_props$row$milestoneT2 = _props$row$milestoneT.data) !== null && _props$row$milestoneT2 !== void 0 && _props$row$milestoneT2.id ? _c('b-link', {
          on: {
            "click": function click($event) {
              return _vm.onShowBoardDetailsPage(props.row.milestoneTaskBoard.data.id);
            }
          }
        }, [_c('b', [_vm._v(_vm._s(props === null || props === void 0 ? void 0 : (_props$row10 = props.row) === null || _props$row10 === void 0 ? void 0 : (_props$row10$mileston = _props$row10.milestoneTaskBoard) === null || _props$row10$mileston === void 0 ? void 0 : (_props$row10$mileston2 = _props$row10$mileston.data) === null || _props$row10$mileston2 === void 0 ? void 0 : _props$row10$mileston2.title))])]) : _c('b-badge', {
          attrs: {
            "variant": "light-danger",
            "pill": ""
          }
        }, [_vm._v(" N / A ")]), _c('br'), _vm._v(" Project Status: "), props !== null && props !== void 0 && (_props$row11 = props.row) !== null && _props$row11 !== void 0 && (_props$row11$mileston = _props$row11.milestoneTaskBoard) !== null && _props$row11$mileston !== void 0 && (_props$row11$mileston2 = _props$row11$mileston.data) !== null && _props$row11$mileston2 !== void 0 && _props$row11$mileston2.status ? _c('b-badge', {
          staticClass: "badge-glow",
          attrs: {
            "variant": _vm.setStatusVariant(props === null || props === void 0 ? void 0 : (_props$row12 = props.row) === null || _props$row12 === void 0 ? void 0 : (_props$row12$mileston = _props$row12.milestoneTaskBoard) === null || _props$row12$mileston === void 0 ? void 0 : (_props$row12$mileston2 = _props$row12$mileston.data) === null || _props$row12$mileston2 === void 0 ? void 0 : _props$row12$mileston2.status)
          }
        }, [_vm._v(" " + _vm._s(_vm.setStatus(props === null || props === void 0 ? void 0 : (_props$row13 = props.row) === null || _props$row13 === void 0 ? void 0 : (_props$row13$mileston = _props$row13.milestoneTaskBoard) === null || _props$row13$mileston === void 0 ? void 0 : (_props$row13$mileston2 = _props$row13$mileston.data) === null || _props$row13$mileston2 === void 0 ? void 0 : _props$row13$mileston2.status)) + " ")]) : _c('b-badge', {
          attrs: {
            "variant": "light-danger",
            "pill": ""
          }
        }, [_vm._v(" N / A ")])], 1), (props === null || props === void 0 ? void 0 : (_props$row14 = props.row) === null || _props$row14 === void 0 ? void 0 : _props$row14.wordcount) > 0 ? _c('div', [_vm._v(" Word Count: "), _c('b-badge', {
          attrs: {
            "variant": "light-info"
          }
        }, [_vm._v(" " + _vm._s(parseInt(props === null || props === void 0 ? void 0 : (_props$row15 = props.row) === null || _props$row15 === void 0 ? void 0 : _props$row15.wordcount)) + " ")])], 1) : _vm._e()], 2)] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column2 = props.column) === null || _props$column2 === void 0 ? void 0 : _props$column2.field) === 'format_member_info' ? [_vm._l(props.row.team.data, function (member, index) {
          return _c('span', {
            key: index
          }, [index <= 2 ? [_c('b-avatar', {
            staticStyle: {
              "margin": "1px"
            },
            attrs: {
              "src": (member === null || member === void 0 ? void 0 : member.avatar) === '' ? '/avatar.svg' : member === null || member === void 0 ? void 0 : member.avatar,
              "size": "26"
            }
          })] : _vm._e()], 2);
        }), (props === null || props === void 0 ? void 0 : (_props$row16 = props.row) === null || _props$row16 === void 0 ? void 0 : _props$row16.team_count) > 3 ? _c('span', [_vm._v(" + " + _vm._s((props === null || props === void 0 ? void 0 : (_props$row17 = props.row) === null || _props$row17 === void 0 ? void 0 : _props$row17.team_count) - 3) + " more ")]) : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$row18 = props.row) === null || _props$row18 === void 0 ? void 0 : _props$row18.team_count) > 0 ? _c('b-button', {
          staticClass: "btn-icon rounded-circle",
          staticStyle: {
            "margin-left": "5px"
          },
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              var _props$row19, _props$row19$team;
              return _vm.showAllAddedMemberModal(props === null || props === void 0 ? void 0 : (_props$row19 = props.row) === null || _props$row19 === void 0 ? void 0 : (_props$row19$team = _props$row19.team) === null || _props$row19$team === void 0 ? void 0 : _props$row19$team.data);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "UsersIcon"
          }
        })], 1) : _c('b-badge', {
          attrs: {
            "variant": "danger"
          }
        }, [_vm._v(" N / A ")])] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column3 = props.column) === null || _props$column3 === void 0 ? void 0 : _props$column3.field) === 'format_phase_info' ? [_c('b-badge', {
          attrs: {
            "variant": "primary"
          }
        }, [_vm._v(" " + _vm._s(props === null || props === void 0 ? void 0 : (_props$row20 = props.row) === null || _props$row20 === void 0 ? void 0 : (_props$row20$phase = _props$row20.phase) === null || _props$row20$phase === void 0 ? void 0 : (_props$row20$phase$da = _props$row20$phase.data) === null || _props$row20$phase$da === void 0 ? void 0 : _props$row20$phase$da.title) + " ")])] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column4 = props.column) === null || _props$column4 === void 0 ? void 0 : _props$column4.field) === 'format_supervisor' ? [props !== null && props !== void 0 && (_props$row21 = props.row) !== null && _props$row21 !== void 0 && (_props$row21$mileston = _props$row21.milestoneTaskBoard) !== null && _props$row21$mileston !== void 0 && (_props$row21$mileston2 = _props$row21$mileston.data) !== null && _props$row21$mileston2 !== void 0 && (_props$row21$mileston3 = _props$row21$mileston2.supervisor) !== null && _props$row21$mileston3 !== void 0 && _props$row21$mileston3.data ? _c('span', [_c('UserCard', {
          attrs: {
            "user": props === null || props === void 0 ? void 0 : (_props$row22 = props.row) === null || _props$row22 === void 0 ? void 0 : (_props$row22$mileston = _props$row22.milestoneTaskBoard) === null || _props$row22$mileston === void 0 ? void 0 : (_props$row22$mileston2 = _props$row22$mileston.data) === null || _props$row22$mileston2 === void 0 ? void 0 : (_props$row22$mileston3 = _props$row22$mileston2.supervisor) === null || _props$row22$mileston3 === void 0 ? void 0 : _props$row22$mileston3.data
          }
        })], 1) : _c('span', [_c('div', {
          staticClass: "text-nowrap"
        }, [_c('span', [_c('b', [_vm._v("No Supervisor Assigned ")])])])])] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column5 = props.column) === null || _props$column5 === void 0 ? void 0 : _props$column5.field) === 'format_date' ? [_c('b', [_vm._v("Deadline: ")]), props !== null && props !== void 0 && (_props$row23 = props.row) !== null && _props$row23 !== void 0 && _props$row23.deadline ? _c('span', [_vm._v(" " + _vm._s(_vm.formatDate(props === null || props === void 0 ? void 0 : (_props$row24 = props.row) === null || _props$row24 === void 0 ? void 0 : _props$row24.deadline)) + " ")]) : _c('span', [_c('b-badge', {
          attrs: {
            "pill": "",
            "variant": "light-primary"
          }
        }, [_vm._v(" N/A ")])], 1), _c('br'), _c('b', [_vm._v("Actual Start Date: ")]), props !== null && props !== void 0 && (_props$row25 = props.row) !== null && _props$row25 !== void 0 && _props$row25.actual_start_date ? _c('span', [_vm._v(" " + _vm._s(_vm.formatDate(props === null || props === void 0 ? void 0 : (_props$row26 = props.row) === null || _props$row26 === void 0 ? void 0 : _props$row26.actual_start_date)) + " ")]) : _c('span', [_c('b-badge', {
          attrs: {
            "pill": "",
            "variant": "light-primary"
          }
        }, [_vm._v(" N/A ")])], 1), _c('br'), _c('b', [_vm._v("Actual End Date: ")]), props !== null && props !== void 0 && (_props$row27 = props.row) !== null && _props$row27 !== void 0 && _props$row27.actual_end_date ? _c('span', [_vm._v(" " + _vm._s(_vm.formatDate(props === null || props === void 0 ? void 0 : (_props$row28 = props.row) === null || _props$row28 === void 0 ? void 0 : _props$row28.actual_end_date)) + " ")]) : _c('span', [_c('b-badge', {
          attrs: {
            "pill": "",
            "variant": "light-primary"
          }
        }, [_vm._v(" N/A ")])], 1)] : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])];
      }
    }, {
      key: "pagination-bottom",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "d-flex justify-content-between flex-wrap"
        }, [_c('div', {
          staticClass: "d-flex align-items-center mb-0 mt-1"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" Showing 1 to ")]), _c('b-form-select', {
          staticClass: "mx-1",
          attrs: {
            "options": ['10', '20', '50']
          },
          on: {
            "input": function input(value) {
              return props.perPageChanged({
                currentPerPage: value
              });
            }
          },
          model: {
            value: _vm.pageLength,
            callback: function callback($$v) {
              _vm.pageLength = $$v;
            },
            expression: "pageLength"
          }
        }), _c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" of " + _vm._s(props.total) + " entries ")])], 1), _c('div', [_c('b-pagination', {
          staticClass: "mt-1 mb-0",
          attrs: {
            "value": 1,
            "total-rows": props.total,
            "per-page": _vm.pageLength,
            "first-number": "",
            "last-number": "",
            "align": "right",
            "prev-class": "prev-item",
            "next-class": "next-item"
          },
          on: {
            "input": function input(value) {
              return props.pageChanged({
                currentPage: value
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "prev-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronLeftIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }, {
            key: "next-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronRightIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }], null, true)
        })], 1)])];
      }
    }])
  })], 1), _c('b-card-actions', {
    attrs: {
      "title": 'Task (' + ((_vm$taskStatistics = _vm.taskStatistics) === null || _vm$taskStatistics === void 0 ? void 0 : (_vm$taskStatistics$da = _vm$taskStatistics.data) === null || _vm$taskStatistics$da === void 0 ? void 0 : _vm$taskStatistics$da.total_task_count) + ')',
      "action-collapse": ""
    }
  }, [_c('vue-good-table', {
    attrs: {
      "mode": "remote",
      "style-class": "vgt-table table-custom-style striped",
      "total-rows": _vm.totalRecords,
      "is-loading": _vm.isLoading,
      "rows": _vm.rows,
      "sort-options": {
        enabled: false,
        multipleColumns: true,
        initialSortBy: [{
          field: 'created_at',
          type: 'desc'
        }]
      },
      "columns": _vm.columns,
      "pagination-options": {
        enabled: true,
        perPage: _vm.pageLength
      }
    },
    on: {
      "update:isLoading": function updateIsLoading($event) {
        _vm.isLoading = $event;
      },
      "update:is-loading": function updateIsLoading($event) {
        _vm.isLoading = $event;
      },
      "on-page-change": _vm.onPageChange,
      "on-per-page-change": _vm.onPerPageChange
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        var _props$column6, _props$row29, _props$row30, _props$row31, _props$row32, _props$row32$mileston, _props$row32$mileston2, _props$row32$mileston3, _props$row32$mileston4, _props$row33, _props$row33$mileston, _props$row33$mileston2, _props$row33$mileston3, _props$row33$mileston4, _props$row34, _props$row34$mileston, _props$row34$mileston2, _props$row34$mileston3, _props$row34$mileston4, _props$row35, _props$row35$phase, _props$row35$phase$da, _props$row36, _props$row36$phase, _props$row36$phase$da, _props$row$milestoneT3, _props$row$milestoneT4, _props$row37, _props$row37$mileston, _props$row37$mileston2, _props$row38, _props$row38$mileston, _props$row38$mileston2, _props$row39, _props$row39$mileston, _props$row39$mileston2, _props$row40, _props$row40$mileston, _props$row40$mileston2, _props$row41, _props$row42, _props$column7, _props$row43, _props$row44, _props$row45, _props$column8, _props$row47, _props$row47$phase, _props$row47$phase$da, _props$column9, _props$row48, _props$row48$mileston, _props$row48$mileston2, _props$row48$mileston3, _props$row49, _props$row49$mileston, _props$row49$mileston2, _props$row49$mileston3, _props$column10, _props$row50, _props$row51, _props$row52, _props$row53, _props$row54, _props$row55;
        return [(props === null || props === void 0 ? void 0 : (_props$column6 = props.column) === null || _props$column6 === void 0 ? void 0 : _props$column6.field) === 'format_tasks' ? [_c('span', [_c('b-link', {
          on: {
            "click": function click($event) {
              return _vm.onShowTaskDetailsPage(props.row.id, props.row.short_title);
            }
          }
        }, [_c('div', {
          staticClass: "font-weight-bold",
          staticStyle: {
            "color": "black"
          },
          domProps: {
            "innerHTML": _vm._s(_vm.breakTitle(props === null || props === void 0 ? void 0 : (_props$row29 = props.row) === null || _props$row29 === void 0 ? void 0 : _props$row29.title))
          }
        })]), props !== null && props !== void 0 && (_props$row30 = props.row) !== null && _props$row30 !== void 0 && _props$row30.is_completed ? _c('b-badge', {
          staticClass: "badge-glow",
          attrs: {
            "variant": "light-success"
          }
        }, [_vm._v(" Completed ")]) : _vm._e(), props !== null && props !== void 0 && (_props$row31 = props.row) !== null && _props$row31 !== void 0 && _props$row31.overdue ? _c('b-badge', {
          attrs: {
            "variant": "light-danger"
          }
        }, [_vm._v(" Overdue ")]) : _vm._e(), _c('div', [_vm._v(" Department: "), props !== null && props !== void 0 && (_props$row32 = props.row) !== null && _props$row32 !== void 0 && (_props$row32$mileston = _props$row32.milestoneTaskBoard) !== null && _props$row32$mileston !== void 0 && (_props$row32$mileston2 = _props$row32$mileston.data) !== null && _props$row32$mileston2 !== void 0 && (_props$row32$mileston3 = _props$row32$mileston2.department) !== null && _props$row32$mileston3 !== void 0 && (_props$row32$mileston4 = _props$row32$mileston3.data) !== null && _props$row32$mileston4 !== void 0 && _props$row32$mileston4.name ? [props !== null && props !== void 0 && (_props$row33 = props.row) !== null && _props$row33 !== void 0 && (_props$row33$mileston = _props$row33.milestoneTaskBoard) !== null && _props$row33$mileston !== void 0 && (_props$row33$mileston2 = _props$row33$mileston.data) !== null && _props$row33$mileston2 !== void 0 && (_props$row33$mileston3 = _props$row33$mileston2.department) !== null && _props$row33$mileston3 !== void 0 && (_props$row33$mileston4 = _props$row33$mileston3.data) !== null && _props$row33$mileston4 !== void 0 && _props$row33$mileston4.name ? _c('b-badge', {
          attrs: {
            "variant": "light-info",
            "pill": ""
          }
        }, [_vm._v(" " + _vm._s(props === null || props === void 0 ? void 0 : (_props$row34 = props.row) === null || _props$row34 === void 0 ? void 0 : (_props$row34$mileston = _props$row34.milestoneTaskBoard) === null || _props$row34$mileston === void 0 ? void 0 : (_props$row34$mileston2 = _props$row34$mileston.data) === null || _props$row34$mileston2 === void 0 ? void 0 : (_props$row34$mileston3 = _props$row34$mileston2.department) === null || _props$row34$mileston3 === void 0 ? void 0 : (_props$row34$mileston4 = _props$row34$mileston3.data) === null || _props$row34$mileston4 === void 0 ? void 0 : _props$row34$mileston4.name) + " ")]) : _vm._e()] : _c('b-badge', {
          attrs: {
            "variant": "light-danger",
            "pill": ""
          }
        }, [_vm._v(" N / A ")])], 2), _vm._v(" Phase: "), props !== null && props !== void 0 && (_props$row35 = props.row) !== null && _props$row35 !== void 0 && (_props$row35$phase = _props$row35.phase) !== null && _props$row35$phase !== void 0 && (_props$row35$phase$da = _props$row35$phase.data) !== null && _props$row35$phase$da !== void 0 && _props$row35$phase$da.title ? [_c('b-badge', {
          attrs: {
            "variant": "light-primary"
          }
        }, [_vm._v(" " + _vm._s(props === null || props === void 0 ? void 0 : (_props$row36 = props.row) === null || _props$row36 === void 0 ? void 0 : (_props$row36$phase = _props$row36.phase) === null || _props$row36$phase === void 0 ? void 0 : (_props$row36$phase$da = _props$row36$phase.data) === null || _props$row36$phase$da === void 0 ? void 0 : _props$row36$phase$da.title) + " ")])] : _c('b-badge', {
          attrs: {
            "variant": "light-danger",
            "pill": ""
          }
        }, [_vm._v(" N / A ")]), _c('div', [_vm._v(" Milestone Title: "), (_props$row$milestoneT3 = props.row.milestoneTaskBoard) !== null && _props$row$milestoneT3 !== void 0 && (_props$row$milestoneT4 = _props$row$milestoneT3.data) !== null && _props$row$milestoneT4 !== void 0 && _props$row$milestoneT4.id ? _c('b-link', {
          on: {
            "click": function click($event) {
              return _vm.onShowBoardDetailsPage(props.row.milestoneTaskBoard.data.id);
            }
          }
        }, [_c('b', [_vm._v(_vm._s(props === null || props === void 0 ? void 0 : (_props$row37 = props.row) === null || _props$row37 === void 0 ? void 0 : (_props$row37$mileston = _props$row37.milestoneTaskBoard) === null || _props$row37$mileston === void 0 ? void 0 : (_props$row37$mileston2 = _props$row37$mileston.data) === null || _props$row37$mileston2 === void 0 ? void 0 : _props$row37$mileston2.title))])]) : _c('b-badge', {
          attrs: {
            "variant": "light-danger",
            "pill": ""
          }
        }, [_vm._v(" N / A ")]), _c('br'), _vm._v(" Project Status: "), props !== null && props !== void 0 && (_props$row38 = props.row) !== null && _props$row38 !== void 0 && (_props$row38$mileston = _props$row38.milestoneTaskBoard) !== null && _props$row38$mileston !== void 0 && (_props$row38$mileston2 = _props$row38$mileston.data) !== null && _props$row38$mileston2 !== void 0 && _props$row38$mileston2.status ? _c('b-badge', {
          staticClass: "badge-glow",
          attrs: {
            "variant": _vm.setStatusVariant(props === null || props === void 0 ? void 0 : (_props$row39 = props.row) === null || _props$row39 === void 0 ? void 0 : (_props$row39$mileston = _props$row39.milestoneTaskBoard) === null || _props$row39$mileston === void 0 ? void 0 : (_props$row39$mileston2 = _props$row39$mileston.data) === null || _props$row39$mileston2 === void 0 ? void 0 : _props$row39$mileston2.status)
          }
        }, [_vm._v(" " + _vm._s(_vm.setStatus(props === null || props === void 0 ? void 0 : (_props$row40 = props.row) === null || _props$row40 === void 0 ? void 0 : (_props$row40$mileston = _props$row40.milestoneTaskBoard) === null || _props$row40$mileston === void 0 ? void 0 : (_props$row40$mileston2 = _props$row40$mileston.data) === null || _props$row40$mileston2 === void 0 ? void 0 : _props$row40$mileston2.status)) + " ")]) : _c('b-badge', {
          attrs: {
            "variant": "light-danger",
            "pill": ""
          }
        }, [_vm._v(" N / A ")])], 1), (props === null || props === void 0 ? void 0 : (_props$row41 = props.row) === null || _props$row41 === void 0 ? void 0 : _props$row41.wordcount) > 0 ? _c('div', [_vm._v(" Word Count: "), _c('b-badge', {
          attrs: {
            "variant": "light-info"
          }
        }, [_vm._v(" " + _vm._s(parseInt(props === null || props === void 0 ? void 0 : (_props$row42 = props.row) === null || _props$row42 === void 0 ? void 0 : _props$row42.wordcount)) + " ")])], 1) : _vm._e()], 2)] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column7 = props.column) === null || _props$column7 === void 0 ? void 0 : _props$column7.field) === 'format_member_info' ? [_vm._l(props.row.team.data, function (member, index) {
          return _c('span', {
            key: index
          }, [index <= 2 ? [_c('b-avatar', {
            staticStyle: {
              "margin": "1px"
            },
            attrs: {
              "src": (member === null || member === void 0 ? void 0 : member.avatar) === '' ? '/avatar.svg' : member === null || member === void 0 ? void 0 : member.avatar,
              "size": "26"
            }
          })] : _vm._e()], 2);
        }), (props === null || props === void 0 ? void 0 : (_props$row43 = props.row) === null || _props$row43 === void 0 ? void 0 : _props$row43.team_count) > 3 ? _c('span', [_vm._v(" + " + _vm._s((props === null || props === void 0 ? void 0 : (_props$row44 = props.row) === null || _props$row44 === void 0 ? void 0 : _props$row44.team_count) - 3) + " more ")]) : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$row45 = props.row) === null || _props$row45 === void 0 ? void 0 : _props$row45.team_count) > 0 ? _c('b-button', {
          staticClass: "btn-icon rounded-circle",
          staticStyle: {
            "margin-left": "5px"
          },
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              var _props$row46, _props$row46$team;
              return _vm.showAllAddedMemberModal(props === null || props === void 0 ? void 0 : (_props$row46 = props.row) === null || _props$row46 === void 0 ? void 0 : (_props$row46$team = _props$row46.team) === null || _props$row46$team === void 0 ? void 0 : _props$row46$team.data);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "UsersIcon"
          }
        })], 1) : _c('b-badge', {
          attrs: {
            "variant": "danger"
          }
        }, [_vm._v(" N / A ")])] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column8 = props.column) === null || _props$column8 === void 0 ? void 0 : _props$column8.field) === 'format_phase_info' ? [_c('b-badge', {
          attrs: {
            "variant": "primary"
          }
        }, [_vm._v(" " + _vm._s(props === null || props === void 0 ? void 0 : (_props$row47 = props.row) === null || _props$row47 === void 0 ? void 0 : (_props$row47$phase = _props$row47.phase) === null || _props$row47$phase === void 0 ? void 0 : (_props$row47$phase$da = _props$row47$phase.data) === null || _props$row47$phase$da === void 0 ? void 0 : _props$row47$phase$da.title) + " ")])] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column9 = props.column) === null || _props$column9 === void 0 ? void 0 : _props$column9.field) === 'format_supervisor' ? [props !== null && props !== void 0 && (_props$row48 = props.row) !== null && _props$row48 !== void 0 && (_props$row48$mileston = _props$row48.milestoneTaskBoard) !== null && _props$row48$mileston !== void 0 && (_props$row48$mileston2 = _props$row48$mileston.data) !== null && _props$row48$mileston2 !== void 0 && (_props$row48$mileston3 = _props$row48$mileston2.supervisor) !== null && _props$row48$mileston3 !== void 0 && _props$row48$mileston3.data ? _c('span', [_c('UserCard', {
          attrs: {
            "user": props === null || props === void 0 ? void 0 : (_props$row49 = props.row) === null || _props$row49 === void 0 ? void 0 : (_props$row49$mileston = _props$row49.milestoneTaskBoard) === null || _props$row49$mileston === void 0 ? void 0 : (_props$row49$mileston2 = _props$row49$mileston.data) === null || _props$row49$mileston2 === void 0 ? void 0 : (_props$row49$mileston3 = _props$row49$mileston2.supervisor) === null || _props$row49$mileston3 === void 0 ? void 0 : _props$row49$mileston3.data
          }
        })], 1) : _c('span', [_c('div', {
          staticClass: "text-nowrap"
        }, [_c('span', [_c('b', [_vm._v("No Supervisor Assigned ")])])])])] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column10 = props.column) === null || _props$column10 === void 0 ? void 0 : _props$column10.field) === 'format_date' ? [_c('b', [_vm._v("Deadline: ")]), props !== null && props !== void 0 && (_props$row50 = props.row) !== null && _props$row50 !== void 0 && _props$row50.deadline ? _c('span', [_vm._v(" " + _vm._s(_vm.formatDate(props === null || props === void 0 ? void 0 : (_props$row51 = props.row) === null || _props$row51 === void 0 ? void 0 : _props$row51.deadline)) + " ")]) : _c('span', [_c('b-badge', {
          attrs: {
            "pill": "",
            "variant": "light-primary"
          }
        }, [_vm._v(" N/A ")])], 1), _c('br'), _c('b', [_vm._v("Actual Start Date: ")]), props !== null && props !== void 0 && (_props$row52 = props.row) !== null && _props$row52 !== void 0 && _props$row52.actual_start_date ? _c('span', [_vm._v(" " + _vm._s(_vm.formatDate(props === null || props === void 0 ? void 0 : (_props$row53 = props.row) === null || _props$row53 === void 0 ? void 0 : _props$row53.actual_start_date)) + " ")]) : _c('span', [_c('b-badge', {
          attrs: {
            "pill": "",
            "variant": "light-primary"
          }
        }, [_vm._v(" N/A ")])], 1), _c('br'), _c('b', [_vm._v("Actual End Date: ")]), props !== null && props !== void 0 && (_props$row54 = props.row) !== null && _props$row54 !== void 0 && _props$row54.actual_end_date ? _c('span', [_vm._v(" " + _vm._s(_vm.formatDate(props === null || props === void 0 ? void 0 : (_props$row55 = props.row) === null || _props$row55 === void 0 ? void 0 : _props$row55.actual_end_date)) + " ")]) : _c('span', [_c('b-badge', {
          attrs: {
            "pill": "",
            "variant": "light-primary"
          }
        }, [_vm._v(" N/A ")])], 1)] : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])];
      }
    }, {
      key: "pagination-bottom",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "d-flex justify-content-between flex-wrap"
        }, [_c('div', {
          staticClass: "d-flex align-items-center mb-0 mt-1"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" Showing 1 to ")]), _c('b-form-select', {
          staticClass: "mx-1",
          attrs: {
            "options": ['10', '20', '50']
          },
          on: {
            "input": function input(value) {
              return props.perPageChanged({
                currentPerPage: value
              });
            }
          },
          model: {
            value: _vm.pageLength,
            callback: function callback($$v) {
              _vm.pageLength = $$v;
            },
            expression: "pageLength"
          }
        }), _c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" of " + _vm._s(props.total) + " entries ")])], 1), _c('div', [_c('b-pagination', {
          staticClass: "mt-1 mb-0",
          attrs: {
            "value": 1,
            "total-rows": props.total,
            "per-page": _vm.pageLength,
            "first-number": "",
            "last-number": "",
            "align": "right",
            "prev-class": "prev-item",
            "next-class": "next-item"
          },
          on: {
            "input": function input(value) {
              return props.pageChanged({
                currentPage: value
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "prev-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronLeftIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }, {
            key: "next-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronRightIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }], null, true)
        })], 1)])];
      }
    }])
  })], 1), _c('b-modal', {
    attrs: {
      "id": "modal-all-added-member-form",
      "centered": "",
      "title": "Assigned Members",
      "hide-footer": "",
      "no-close-on-backdrop": ""
    },
    on: {
      "hidden": _vm.hiddenAllAddedMemberModal
    }
  }, [_c('ul', _vm._l(_vm.assingedMember, function (member, index) {
    return _c('li', {
      key: index
    }, [_c('b-avatar', {
      staticStyle: {
        "margin": "1px"
      },
      attrs: {
        "src": (member === null || member === void 0 ? void 0 : member.avatar) === '' ? '/avatar.svg' : member === null || member === void 0 ? void 0 : member.avatar,
        "size": "26"
      }
    }), _vm._v(" " + _vm._s(member.name) + " ")], 1);
  }), 0)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }